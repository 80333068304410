exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-afspraak-tsx": () => import("./../../../src/pages/afspraak.tsx" /* webpackChunkName: "component---src-pages-afspraak-tsx" */),
  "component---src-pages-algemene-voorwaarden-tsx": () => import("./../../../src/pages/algemene-voorwaarden.tsx" /* webpackChunkName: "component---src-pages-algemene-voorwaarden-tsx" */),
  "component---src-pages-booking-page-detail-tsx": () => import("./../../../src/pages/booking-page-detail.tsx" /* webpackChunkName: "component---src-pages-booking-page-detail-tsx" */),
  "component---src-pages-confirmation-tsx": () => import("./../../../src/pages/confirmation.tsx" /* webpackChunkName: "component---src-pages-confirmation-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-diensten-tsx": () => import("./../../../src/pages/diensten.tsx" /* webpackChunkName: "component---src-pages-diensten-tsx" */),
  "component---src-pages-disclaimer-tsx": () => import("./../../../src/pages/disclaimer.tsx" /* webpackChunkName: "component---src-pages-disclaimer-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-gdpr-tsx": () => import("./../../../src/pages/gdpr.tsx" /* webpackChunkName: "component---src-pages-gdpr-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kmo-tsx": () => import("./../../../src/pages/kmo.tsx" /* webpackChunkName: "component---src-pages-kmo-tsx" */),
  "component---src-pages-nieuwsbrief-tsx": () => import("./../../../src/pages/nieuwsbrief.tsx" /* webpackChunkName: "component---src-pages-nieuwsbrief-tsx" */),
  "component---src-pages-over-ons-tsx": () => import("./../../../src/pages/over-ons.tsx" /* webpackChunkName: "component---src-pages-over-ons-tsx" */),
  "component---src-pages-testimonials-tsx": () => import("./../../../src/pages/testimonials.tsx" /* webpackChunkName: "component---src-pages-testimonials-tsx" */)
}

